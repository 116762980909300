<template>
	<lidaPage :columns="tableHead" :query="userMyUserPage" :formColumns="formColumns" :showResetBtn="false" subTitle="总客户数">
		<template #startBusinessTime="scope">
			<div>{{(scope.row.startBusinessTime || '') + '-' + (scope.row.endBusinessTime || '')}}</div>
		</template>
		<template #enterpriseStatus="scope">
			<div>{{scope.row.enterpriseStatus === '1' ? '禁用' : '启用'}}</div>
		</template>
		<template #annualTurnover="scope">
			<div>￥{{scope.row.annualTurnover}}</div>
		</template>
		<template #operation>
			<lida-table-column label="操作" width="50" fixed="right">
				<template #default="scope">
					<el-button @click="commands(scope.row)" type="text">查看</el-button>
				</template>
			</lida-table-column>
		</template>
	</lidaPage>
</template>
<script>
	import {
		mapActions
	} from 'vuex'
	export default {
		data() {
			return {
				tableHead: [ // 表格头部
					{label: '公司名称',prop: 'enterpriseName',width:'300'},
					{label: '公司ID',prop: 'enterpriseCode',width:'300'},

					{label: '客户类型',prop: 'legalPersonName'},
					{label: '注册时间',prop: 'establishedTime'},
					{label: '地址',prop: 'address'},
					{label: '法人',prop: 'legalPersonName'},
					{label: '联系人',prop: 'legalPersonName'},
					{label: '电话',prop: 'legalPersonName'},
					{label: '企业统一社会信用编码',prop: 'socialCreditCode'},
					{label: '行政编码',prop: 'enterpriseNationalCode'},
					{label: '营业期限',prop: 'startBusinessTime'},
					// {label: '企业注册资本法定代表人',prop: 'legalPersonName'},
					// {label: '企业注册时间',prop: 'establishedTime'},
					// {label: '企业统一社会信用编码',prop: 'socialCreditCode'},
					// {label: '企业类型',prop: 'enterpriseType'},
					// {label: '行政编码',prop: 'enterpriseNationalCode'},
					// {label: '营业期限',prop: 'startBusinessTime'},
					// {label: '经营范围',prop: 'businessScope'},
					// {label: '企业注册地址',prop: 'enterpriseAddr'},
					// {label: '企业状态',prop: 'enterpriseStatus'},
					// {label: '企业类型',prop: 'enterpriseOcrType'},
					// {label: '网址',prop: 'website'},
					// {label: '办公区域/详细地址',prop: 'address'},
					// {label: '电话',prop: 'legalPersonName'},
					// {label: '传真',prop: 'fax'},
					// {label: '员工人数',prop: 'staffNum'},
					// {label: '年营业额',prop: 'annualTurnover'},
				],
				formColumns: [
					{
						type: 'input',
						prop: 'keyword',
						label: '综合搜索',
						placeholder:'请输入名字、联系人、法人等关键字'
					},
					// {
					// 	type: 'area',
					// 	prop: 'address',
					// 	label: '地址',
					// },
				],
			}
		},
		methods: {
			...mapActions('clientModule',[
				'userMyUserPage', //我的客户页面
			]),
			commands(row){ //操作的点击事件
				this.$router.push({ //跳转到详情页
					path:'/client/enterpriseDetails',
					query:{
						id:row.id,
					}
				})
			},
		},
	};
</script>
<style lang="less" scoped>
	.line{
		background: #F2F5FA;
		height: 10px;
		width: calc(100% + 20px);
		position: relative;
		left: -10px;
	}
</style>
